<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { nanoid } from 'nanoid';
import { useConfirmDialog, useTitle } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { HTTPError } from 'ky';
import { Decimal } from 'decimal.js';
import { DateTime } from 'luxon';
import { useModal } from 'vue-final-modal';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLink,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  EmptyValue,
  FontIcon,
  FormInput,
  FormLabel,
  FormTextarea,
  HelpInformation,
  InvoiceExternalStatus,
  InvoiceInternalStatus,
  InvoiceSyncStatus,
  LeaveConfirmModal,
} from '@/components';
import api from '@/services/api';
import toast from '@/services/toast';
import { calculateAmount } from '@/services/invoices';
import useLoader from '@/composables/useLoader';
import useCountriesStore from '@/store/CountriesStore';
import useTrackChanges from '@/composables/useTrackChanges';
import useInvoiceItem from '@/composables/useInvoiceItem';
import {
  IInvoiceItemResource,
  IInvoiceRequestBody,
  IInvoiceResource,
  InvoiceBaseItemForm,
  InvoiceCustomItemForm,
  InvoiceInternalStatusType,
  InvoiceItemForm,
  InvoiceItemPriceType,
} from '@/types/Invoice';
import { ClientInvoiceLanguage, IClientFullResource } from '@/types/Client';
import { IUserListResource } from '@/types/User';
import { IProjectBillingResource, IProjectMargin, IProjectPreviewResource } from '@/types/Project';
import { IInvoiceItemTemplateResource } from '@/types/InvoiceItemTemplate';
import { IErpArticleResource } from '@/types/Article';
import { ErpType } from '@/types/Erp';
import useInvoice from '@/composables/useInvoice';
import usePermissions from '@/composables/usePermissions';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { IContactFullResource } from '@/types/Contact';
import useUsers from '@/composables/useUsers';
import { useInvoiceItemsState } from '@/store/InvoiceItemsState';

const props = defineProps<{
  id: number;
}>();

const router = useRouter();
const { t, n, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const submitLoader = useLoader({ useProgress: false });
const syncLoader = useLoader({ useProgress: false });
const { isRevealed, reveal, confirm, cancel } = useConfirmDialog();
const countriesStore = useCountriesStore();
const { getCountries } = countriesStore;
const { countries } = storeToRefs(countriesStore);
const { openFetchNotInvoicedModal } = useInvoice();
const {
  isExpandedInvoiceItem,
  expandInvoiceItem,
  toggleInvoiceItem,
  collapseInvoiceItem,
  toggleAllInvoiceItems,
  isExpandedInvoiceItemsEmpty,
} = useInvoiceItemsState();
const { can } = usePermissions();
const { getMergedUsersList } = useUsers();
const {
  onCustomInvoicingQtyChange,
  onCustomPricePerUnitChange,
  onCustomInvoicingSumChange,

  onDiscountChange,

  getInvoicingSum,
  onInvoicingQtyChange,
  onInvoicingPricePerUnitChange,
  onInvoicingSumChange,

  onPostpone,
  discardPostpone,
  onPostponeQtyChange,
  onPostponePricePerUnitChange,
  onPostponeSumChange,

  onWriteOff,
  discardWriteOff,
  onWriteOffSumChange,

  isInvoiceItemValid,
} = useInvoiceItem();

const invoice = ref<null | IInvoiceResource>(null);
const client = ref<null | IClientFullResource>(null);

const users = ref<IUserListResource[]>([]);
const contacts = ref<IContactFullResource[]>([]);
const projects = ref<IProjectPreviewResource[]>([]);
const invoiceItemManualTemplates = ref<IInvoiceItemTemplateResource[]>([]);
const erpArticles = ref<IErpArticleResource[]>([]);
const projectMargins = ref<{ [key: number]: IProjectMargin }>({});

const closestInvoices = reactive<{ next: null | number; prev: null | number }>({
  next: null,
  prev: null,
});

const includesInvoiceItemWithoutRequiredComment = computed(() => {
  return form.invoice_items
    .filter(({ custom }) => custom === false)
    .some((invoiceItem) => {
      const item = invoiceItem as InvoiceItemForm;
      return item.write_off.sum > 0 && item.write_off.comment === '';
    });
});

const totalPrice = computed(() => {
  return form.invoice_items
    .reduce(
      (total, invoiceItem) => total[invoiceItem.is_inverted_amount ? 'minus' : 'plus'](invoiceItem.price),
      new Decimal(0),
    )
    .toNumber();
});

const isDraft = computed(() => invoice.value?.internal_status === InvoiceInternalStatusType.DRAFT);

const isActionRequired = computed(() => invoice.value?.internal_status === InvoiceInternalStatusType.ACTION_REQUIRED);

const requestData = computed<IInvoiceRequestBody>(() => {
  return {
    client_contact_uuid: form.client_contact_uuid,
    user_uuid: form.user_uuid,
    language: form.language,
    internal_note: form.internal_note,
    other_info: form.other_info,
    invoice_text: form.invoice_text,
    invoice_items: form.invoice_items
      .filter((invoice) => !invoice.custom)
      .map((invoice) => {
        const item = invoice as InvoiceItemForm;
        return {
          id: item.id,
          erp_article_id: item.erp_article_id,
          description: item.description,
          discount: item.discount,
          quantity: item.quantity,
          price: item.price,
          price_per_unit: item.price_per_unit,
          postpone: item.postpone,
          write_off: item.write_off,
        };
      }),
    custom_invoice_items: form.invoice_items
      .filter((invoice) => invoice.custom)
      .map((invoice) => {
        const item = invoice as InvoiceCustomItemForm;
        return {
          ...(invoice.id ? { id: invoice.id } : {}),
          project_id: item.project_id,
          erp_article_id: item.erp_article_id,
          invoice_item_template_id: item.invoice_template_id,
          quantity: item.quantity,
          sum: item.price,
          price_per_unit: item.price_per_unit,
          discount: item.discount,
          description: item.description,
        };
      }),
  };
});

type InvoiceForm = Pick<
  IInvoiceRequestBody,
  'client_contact_uuid' | 'user_uuid' | 'language' | 'internal_note' | 'invoice_text' | 'other_info'
> & {
  invoice_items: (InvoiceItemForm | InvoiceCustomItemForm)[];
};

const form = reactive<InvoiceForm>({
  client_contact_uuid: null,
  user_uuid: null,
  language: ClientInvoiceLanguage.SV,
  internal_note: '',
  invoice_text: '',
  other_info: '',
  invoice_items: [],
});

const tracker = useTrackChanges(form);

const country = computed(
  () =>
    countries.value.find(({ code }) => code === invoice.value?.billing_address.country)?.name[locale.value] ??
    invoice.value?.billing_address.country,
);

function getErpArticle(id: number) {
  return erpArticles.value.find((erpArticle) => erpArticle.id === id) ?? null;
}

function getInvoiceItemManualTemplate(id: number | null | undefined) {
  if (!id) return null;
  return invoiceItemManualTemplates.value.find((templateId) => templateId.id === id) ?? null;
}

const editable = computed(() => {
  if (invoice.value === null) return false;
  return [
    InvoiceInternalStatusType.DRAFT,
    InvoiceInternalStatusType.ACTION_REQUIRED,
    InvoiceInternalStatusType.UNMATCHED,
  ].includes(invoice.value.internal_status);
});

async function getInvoice() {
  try {
    const response = await api.invoices.get(props.id);
    invoice.value = response.data;
    setInvoiceForm(response.data);
    closestInvoices.next = response.next;
    closestInvoices.prev = response.prev;
  } catch (error: unknown) {
    if (error instanceof HTTPError && error.response.status === 404) {
      await router.push({ name: 'NotFound' });
      throw error;
    }
  }
}

function setInvoiceItemForm(invoiceItem: IInvoiceItemResource): InvoiceItemForm | InvoiceCustomItemForm {
  const invoiceBaseItem: InvoiceBaseItemForm = {
    id: invoiceItem.id,
    uid: nanoid(),
    custom: invoiceItem.custom,
    price_type: invoiceItem.price_type,
    description: invoiceItem.description ?? '',
    quantity: invoiceItem.quantity,
    price_per_unit: invoiceItem.price_per_unit,
    discount: invoiceItem.discount,
    is_inverted_amount: invoiceItem.invoice_item_template?.is_inverted_amount ?? false,
    project_id: invoiceItem.project.id,
    project_name: invoiceItem.project.name,
    erp_article_id: invoiceItem.erp_article?.id ?? null,
    invoice_template_id: invoiceItem.invoice_item_template?.id ?? null,
    unit: invoiceItem.erp_article?.unit ?? null,
    price: Decimal.mul(invoiceItem.price_per_unit, invoiceItem.quantity).toNumber(),
  };

  // Set custom invoice item
  if (invoiceItem.custom) {
    return invoiceBaseItem as InvoiceCustomItemForm;
  }

  // Set system invoice item
  return {
    ...invoiceBaseItem,
    invoiced_period: invoiceItem.invoiced_period,
    original_quantity: invoiceItem.original_quantity,
    original_price_per_unit: invoiceItem.original_price_per_unit,
    original_price: Decimal.mul(invoiceItem.original_quantity, invoiceItem.original_price_per_unit)
      .toDecimalPlaces(2)
      .toNumber(),
    postpone: {
      quantity: invoiceItem.postpone?.quantity ?? 0,
      comment: invoiceItem.postpone?.comment ?? '',
      price_per_unit: invoiceItem.postpone?.price_per_unit ?? 0,
      sum: invoiceItem.postpone?.sum ?? 0,
    },
    write_off: {
      quantity: invoiceItem.write_off?.quantity ?? 0,
      comment: invoiceItem.write_off?.comment ?? '',
      price_per_unit: invoiceItem.write_off?.price_per_unit ?? 0,
      sum: invoiceItem.write_off?.sum ?? 0,
    },
  } as InvoiceItemForm;
}

function setInvoiceForm(invoice: IInvoiceResource) {
  form.client_contact_uuid ||= invoice.clientContact?.uuid;
  form.language = invoice.language ? invoice.language : form.language;
  form.user_uuid ||= invoice.user?.uuid;
  form.internal_note = invoice.internal_note ?? '';
  form.invoice_text = invoice.invoice_text ?? '';
  form.other_info = invoice.other_info ?? '';
  form.invoice_items = invoice.invoiceItems.map(setInvoiceItemForm);
}

function addCustomItem() {
  const selectedProject = projects.value.length === 1 ? projects.value[0] : null;
  const newCustomInvoiceItem: InvoiceCustomItemForm = {
    uid: nanoid(),
    custom: true,
    price_type: null,
    description: '',
    quantity: 0,
    price_per_unit: 0,
    discount: 0,
    price: 0,
    is_inverted_amount: false,
    project_id: selectedProject?.id ?? null,
    project_name: selectedProject?.name ?? null,
    erp_article_id: null,
    invoice_template_id: null,
    unit: null,
  };
  form.invoice_items.push(newCustomInvoiceItem);
}

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getContacts() {
  if (invoice.value === null) return;
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.clients.contacts.list(invoice.value.client.uuid, { searchParams });
    contacts.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getProjects() {
  if (invoice.value === null) return;
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    ['active', 'cancelled', 'done'].forEach((status) => {
      searchParams.append('statuses[]', status);
    });
    const response = await api.clients.projects.list(invoice.value.client.uuid, { searchParams });
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function onFetchNotInvoiced() {
  openFetchNotInvoicedModal(
    {
      client: invoice.value!.client.uuid,
      time_period: invoice.value!.time_period.id,
    },
    {
      async onFetched() {
        const {
          data: { invoiceItems },
        } = await api.invoices.get(props.id);
        invoiceItems.forEach((invoiceItem) => {
          if (!form.invoice_items.some(({ id }) => id === invoiceItem.id)) {
            form.invoice_items.push(setInvoiceItemForm(invoiceItem));
          }
        });
      },
    },
  );
}

async function getProjectMargins() {
  if (projects.value.length === 0) return;
  try {
    const searchParams = new URLSearchParams();
    projects.value.forEach(({ id }) => {
      searchParams.append('projects[]', id.toString());
    });
    projectMargins.value = await api.projects.margin(searchParams);
  } catch (error) {
    console.error(error);
  }
}

async function getClient() {
  if (invoice.value === null) return;
  try {
    const response = await api.clients.get(invoice.value.client.uuid);
    client.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getErpArticles() {
  try {
    const response = await api.erpArticles.index();
    erpArticles.value = response.data.filter(({ status }) => status === 'enabled');
  } catch (error) {
    console.error(error);
  }
}

async function getInvoiceItemTemplates() {
  try {
    const response = await api.invoiceItemTemplates.index();
    invoiceItemManualTemplates.value = response.data
      .filter((template) => template.type === 'manual')
      .sort((a, b) => a.price_type.localeCompare(b.price_type));
  } catch (error) {
    console.error(error);
  }
}

function subtractPercent(value: number, percent: number) {
  const num = new Decimal(value);
  const percentage = new Decimal(percent).dividedBy(100);
  return num.minus(num.times(percentage)).toNumber();
}

function validateForm() {
  return form.invoice_items.every((invoiceItem) => {
    if (!invoiceItem.custom && invoiceItem.write_off?.sum > 0 && invoiceItem.write_off?.comment === '') {
      expandInvoiceItem(invoiceItem.uid);
      return false;
    }
    return true;
  });
}

async function save() {
  const isValid = validateForm();
  if (!isValid) {
    toast.error(t('common.messages.fill_the_form'));
    return;
  }
  try {
    submitLoader.start();
    const response = await api.invoices.update(props.id, requestData.value);
    invoice.value = response.data;
    setInvoiceForm(response.data);
    await getProjectsBilling();
    tracker.commit();
    toast.success(t('common.messages.has_been_updated', { name: t('invoice.resource.name') }));
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

async function approveAndSync() {
  try {
    syncLoader.start();
    const response = await api.invoices.approve(requestData.value, props.id);
    invoice.value = response.data;
    setInvoiceForm(response.data);
    tracker.commit();
    if (closestInvoices.next) {
      await router.push({ name: 'invoices.edit', params: { id: closestInvoices.next } });
    }
  } catch (error) {
    console.error(error);
    if (error instanceof HTTPError && error.response && error.response.status === 500) {
      await getInvoice();
    }
  } finally {
    syncLoader.finish();
  }
}

function onDeleteCustomInvoiceItem(uid: string) {
  collapseInvoiceItem(uid);
  form.invoice_items = form.invoice_items.filter((invoice) => invoice.uid !== uid);
}

function onInvoiceItemChoosePriceType(invoiceItem: InvoiceItemForm | InvoiceCustomItemForm) {
  const invoiceItemTemplate = invoiceItemManualTemplates.value.find(({ id }) => id === invoiceItem.invoice_template_id);
  if (!invoiceItemTemplate) return;
  invoiceItem.is_inverted_amount = invoiceItemTemplate.is_inverted_amount;
  invoiceItem.erp_article_id = invoiceItemTemplate.erp_article?.id ?? null;
  setInvoiceItemDescription(invoiceItem);
}

function setInvoiceItemDescription(invoiceItem?: InvoiceItemForm | InvoiceCustomItemForm) {
  if (invoiceItem) {
    const invoiceItemTemplate = invoiceItemManualTemplates.value.find(
      ({ id }) => id === invoiceItem.invoice_template_id,
    );
    if (!invoiceItemTemplate) return;
    const projectName = projects.value.find((p) => p.id === invoiceItem.project_id)?.name ?? '';
    const serviceName = projects.value.find((p) => p.id === invoiceItem.project_id)?.service.name ?? '';
    const timePeriodDate = DateTime.fromObject({
      day: 1,
      month: invoice.value!.time_period.month,
      year: invoice.value!.time_period.year,
    }).setLocale(form.language);
    invoiceItem.description = replaceDescriptionVars(
      form.language ? invoiceItemTemplate[`description_${form.language}`] : '',
      {
        project_name: projectName,
        service_name: serviceName,
        current_month: timePeriodDate.toFormat('MMMM'),
        previous_month: timePeriodDate.minus({ months: 1 }).toFormat('MMMM'),
      },
    );
  } else {
    form.invoice_items
      .filter((invoiceItem) => invoiceItem.custom && !!invoiceItem.invoice_template_id && !!invoiceItem.project_id)
      .forEach((invoiceItem) => {
        const invoiceItemTemplate = invoiceItemManualTemplates.value.find(
          ({ id }) => id === invoiceItem.invoice_template_id,
        );
        if (!invoiceItemTemplate) return;
        const projectName = projects.value.find((p) => p.id === invoiceItem.project_id)?.name ?? '';
        const serviceName = projects.value.find((p) => p.id === invoiceItem.project_id)?.service.name ?? '';
        const timePeriodDate = DateTime.fromObject({
          day: 1,
          month: invoice.value!.time_period.month,
          year: invoice.value!.time_period.year,
        }).setLocale(form.language);
        invoiceItem.description = replaceDescriptionVars(
          form.language ? invoiceItemTemplate[`description_${form.language}`] : '',
          {
            project_name: projectName,
            service_name: serviceName,
            current_month: timePeriodDate.toFormat('MMMM'),
            previous_month: timePeriodDate.minus({ months: 1 }).toFormat('MMMM'),
          },
        );
      });
  }
}

function onInvoiceItemChooseArticle(invoiceItem: InvoiceItemForm | InvoiceCustomItemForm) {
  const erpArticle = erpArticles.value.find(({ id }) => id === invoiceItem.erp_article_id);
  if (!erpArticle) return;
  invoiceItem.unit = erpArticle.unit ?? null;
}

function isInvoiceItemAdvance(invoiceItem: InvoiceItemForm | InvoiceCustomItemForm) {
  if (!invoiceItem.invoice_template_id) return;
  return (
    invoiceItemManualTemplates.value.find((i) => i.id === invoiceItem.invoice_template_id)?.price_type ===
    InvoiceItemPriceType.ADVANCE
  );
}

// Prevent data loss: START
function onBeforeUnload(event: BeforeUnloadEvent) {
  if (!tracker.isModified.value) return true;
  event.preventDefault();
  const customMessage = t('common.confirms.unsaved.title');
  event.returnValue = customMessage;
  return customMessage;
}

onMounted(async () => {
  try {
    loader.start();
    await getInvoice();
    await Promise.all([
      getCountries(),
      getUsers(),
      getContacts(),
      getProjects(),
      getErpArticles(),
      getClient(),
      getInvoiceItemTemplates(),
      getProjectsBilling(),
    ]);

    form.invoice_items.forEach((invoiceItem) => {
      if (invoiceItem.write_off.quantity > 0 && invoiceItem.write_off.comment === '') {
        expandInvoice(invoiceItem.uid);
      }
    });

    await Promise.all([getProjectMargins()]);

    form.invoice_items.forEach((invoiceItem) => {
      if (!invoiceItem.custom && invoiceItem.write_off.sum > 0 && invoiceItem.write_off.comment === '') {
        expandInvoiceItem(invoiceItem.uid);
      }
    });

    // Set default invoice settings from client's settings
    if (!form.other_info && client.value?.invoice_default_info) {
      form.other_info = client.value.invoice_default_info;
    }
    if (!form.language && client.value?.invoice_default_language) {
      form.language = client.value.invoice_default_language;
    }
    if (!form.client_contact_uuid && client.value?.invoice_default_contact) {
      form.client_contact_uuid = client.value.invoice_default_contact.uuid;
    }
    tracker.commit();
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
});

onMounted(() => {
  window.addEventListener('beforeunload', onBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener('beforeunload', onBeforeUnload);
});

onBeforeRouteLeave(async (_to, _from, next) => {
  if (tracker.isModified.value) {
    const { data } = await reveal();
    data ? next() : next(false);
  } else {
    next();
  }
});

function replaceDescriptionVars(template: string, vars: Record<string, string>) {
  return template.replace(/{(\w+)}/g, (_, key) => {
    return vars[key] !== undefined ? vars[key] : `{${key}}`;
  });
}

const title = useTitle(computed(() => t('invoice.edit.title', { id: invoice.value?.id })));

const areInvoiceItemsValid = computed(() =>
  form.invoice_items.filter(({ custom }) => !custom).every((invoiceItem) => isInvoiceItemValid(invoiceItem)),
);

const projectsBilling = ref<IProjectBillingResource[]>([]);

async function getProjectsBilling() {
  if (invoice.value === null) return;
  try {
    const response = await api.clients.billing.projects(invoice.value.client.uuid);
    projectsBilling.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

const projectsWithExistedAdvanceBalance = computed(() =>
  projectsBilling.value.filter((p) => p.is_advance_payments_exists && p.advance_balance !== 0),
);

const projectsWithoutExistedAdvanceBalance = computed(() => {
  return projectsBilling.value
    .filter((p) => !p.is_advance_payments_exists)
    .filter((p) => {
      return form.invoice_items.some((i) => {
        const invoiceItemTemplate = invoiceItemManualTemplates.value.find(({ id }) => id === i.invoice_template_id);
        if (!invoiceItemTemplate) return false;
        return (
          p.id === i.project_id &&
          invoiceItemTemplate.price_type === InvoiceItemPriceType.ADVANCE &&
          i.price_per_unit < 0
        );
      });
    });
});

function showNegativeAdvanceBalanceWarning(invoiceItem: InvoiceItemForm | InvoiceCustomItemForm) {
  const isAdvance = isInvoiceItemAdvance(invoiceItem);
  const invoiceItemTemplate = invoiceItemManualTemplates.value.find(({ id }) => id === invoiceItem.invoice_template_id);
  if (!invoiceItemTemplate) return false;
  const project = projectsBilling.value.find(
    ({ id, is_advance_payments_exists }) => id === invoiceItem.project_id && is_advance_payments_exists,
  );
  if (!project) return false;
  return (
    isAdvance &&
    invoiceItem.project_id === project.id &&
    invoiceItemTemplate.price_type === InvoiceItemPriceType.ADVANCE &&
    project.advance_balance + getInvoicingSum(invoiceItem) < 0
  );
}

function onDelete() {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('invoice.confirm.delete.title'),
      message: t('invoice.confirm.delete.text'),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.invoices.destroy(props.id);
          toast.success(t('common.messages.has_been_deleted', { name: t('invoice.resource.name') }));
          await router.push({ name: 'invoices.drafts' });
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div v-if="!loader.isLoading.value && invoice" class="container-wide">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <AppButton
          @click.prevent="router.push({ name: 'invoices.index' })"
          color="secondary"
          circle
          light
          v-tooltip="t('common.back')"
        >
          <FontIcon name="arrow-back-up" />
        </AppButton>
        <div class="d-flex align-items-end mx-3">
          <h1 class="mb-0" v-text="title" />
          <HelpInformation class="ml-1" translation="invoice.edit.help" />
        </div>
      </div>
    </div>
    <form @submit.prevent="save">
      <AppBox shadow>
        <AppBoxBody>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-12">
                  <RouterLink :to="{ name: 'client.settings', params: { uuid: invoice.client.uuid } }" target="_blank">
                    <h3>{{ invoice.client.name }}</h3>
                  </RouterLink>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 font-bold" v-text="t('invoice.attributes.billing_adress')" />
                <div class="col-12">
                  <div v-if="invoice.billing_address.first_address" v-text="invoice.billing_address.first_address" />
                  <div v-if="invoice.billing_address.second_address" v-text="invoice.billing_address.second_address" />
                  <div v-if="invoice.billing_address.zip" v-text="invoice.billing_address.zip" />
                  <div v-if="invoice.billing_address.city" v-text="invoice.billing_address.city" />
                  <div v-if="country" v-text="country" />
                  <AppLink v-if="invoice.billing_address.email" :value="invoice.billing_address.email" mode="email" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-5 font-bold" v-text="t('invoice.attributes.internal_status')" />
                <div class="col-md-7">
                  <InvoiceInternalStatus :status="invoice.internal_status" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5 font-bold" v-text="t('invoice.attributes.external_status')" />
                <div class="col-md-7">
                  <InvoiceExternalStatus v-if="invoice.external_status" :status="invoice.external_status" />
                  <EmptyValue v-else />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5 font-bold" v-text="t('invoice.attributes.sync_status')" />
                <div class="col-md-7">
                  <InvoiceSyncStatus v-if="invoice.sync_status" :status="invoice.sync_status" />
                  <EmptyValue v-else />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5">
                  <FormLabel class="font-bold" html-for="invoice_client_reference">
                    {{ t('invoice.attributes.client_reference') }}
                  </FormLabel>
                </div>
                <div class="col-md-7">
                  <VueSelect
                    :clearable="true"
                    :options="contacts"
                    :get-option-label="(option:IContactFullResource) => option.short_name ?? option.name"
                    v-model="form.client_contact_uuid"
                    :reduce="(option: IContactFullResource) => option.uuid"
                    input-id="invoice_client_reference"
                    :placeholder="t('common.select')"
                    v-if="editable"
                  >
                  </VueSelect>
                  <span v-else>
                    {{ invoice.clientContact?.name }}
                    {{ invoice.clientContact?.is_deleted ? `(${$t('common.deleted').toLowerCase()})` : '' }}
                  </span>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5">
                  <FormLabel required class="font-bold" html-for="invoice_user">
                    {{ t('invoice.attributes.user') }}
                  </FormLabel>
                </div>
                <div class="col-md-7">
                  <VueSelect
                    :clearable="false"
                    :options="getMergedUsersList(users, invoice?.user)"
                    label="name"
                    v-model="form.user_uuid"
                    :reduce="(option: IUserListResource) => option.uuid"
                    input-id="invoice_user"
                    :placeholder="t('common.select')"
                    v-if="editable"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.user_uuid"
                        v-bind="attributes as object"
                        v-on="events"
                      />
                    </template>
                  </VueSelect>
                  <span v-else v-text="invoice.user?.name" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <FormLabel class="font-bold" html-for="invoice_other_information">
                  {{ t('invoice.attributes.other_information') }}
                </FormLabel>
                <FormTextarea v-if="editable" rows="2" id="invoice_other_information" v-model="form.other_info" />
                <span v-else v-text="form.other_info" />
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <FormLabel required class="font-bold" html-for="invoice_invoice_language">
                    {{ t('invoice.attributes.invoice_language') }}
                  </FormLabel>
                </div>
                <div class="col-md-7">
                  <VueSelect
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="Object.values(ClientInvoiceLanguage)"
                    :get-option-label="(option: string) => t(`common.${option}`)"
                    label="label"
                    input-id="invoice_invoice_language"
                    :placeholder="t('common.select')"
                    v-model="form.language"
                    v-if="editable"
                    @update:model-value="setInvoiceItemDescription()"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.language"
                        v-bind="attributes as object"
                        v-on="events"
                      />
                    </template>
                  </VueSelect>
                  <span v-else v-text="t(`common.${form.language}`)" />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <FormLabel class="font-bold">
                    {{ t('client.attributes.invoice_distribution') }}
                  </FormLabel>
                </div>
                <div class="col-md-7">
                  <span
                    v-if="invoice.invoice_distribution"
                    v-text="t(`invoice.distribution.${invoice.invoice_distribution}`)"
                  />
                  <EmptyValue v-else />
                </div>
              </div>
            </div>
          </div>
          <AppAlert v-if="invoice.sync_error_message" class="mt-3" type="danger">
            {{ invoice.sync_error_message }}
          </AppAlert>
          <AppAlert v-if="invoice.internal_status === InvoiceInternalStatusType.UNMATCHED" class="mt-3" type="danger">
            {{
              t('invoice.unmatched_error', {
                external_amount: n(new Decimal(invoice.external_amount ?? 0).toDecimalPlaces(2).toNumber(), 'currency'),
              })
            }}
            ({{
              n(
                new Decimal(invoice.price ?? 0)
                  .minus(invoice.external_amount ?? 0)
                  .toDecimalPlaces(2)
                  .toNumber(),
                'currency',
              )
            }})
          </AppAlert>
        </AppBoxBody>
      </AppBox>
      <AppTable class="mt-3" hoverable>
        <AppTableHead>
          <AppTableTr :columns-size="24">
            <AppTableTh :column-size="2">{{ t('invoice.details.project') }}</AppTableTh>
            <AppTableTh :column-size="1" nowrap>{{ t('invoice.details.estimated_margin_percent') }}</AppTableTh>
            <AppTableTh :column-size="2">{{ t('invoice.details.price_type') }}</AppTableTh>
            <AppTableTh :column-size="5" nowrap>{{ t('invoice.details.description') }}</AppTableTh>
            <AppTableTh :column-size="2" nowrap />
            <AppTableTh :column-size="3" nowrap>{{ t('invoice.details.article') }}</AppTableTh>
            <AppTableTh :column-size="2" class="text-right" nowrap>{{ t('invoice.details.qty') }}</AppTableTh>
            <AppTableTh :column-size="1" nowrap>{{ t('invoice.details.unit') }}</AppTableTh>
            <AppTableTh :column-size="2" class="text-right" nowrap>{{ t('invoice.details.price_unit') }}</AppTableTh>
            <AppTableTh :column-size="2" class="text-right" nowrap>
              {{ t('invoice.details.discount') }}
            </AppTableTh>
            <AppTableTh :column-size="2" class="text-right" nowrap>{{ t('invoice.details.amount') }}</AppTableTh>
            <AppTableTh :column-size="1" class="text-right" nowrap>
              <AppButton
                v-if="form.invoice_items.length > 0"
                @click.stop.prevent="toggleAllInvoiceItems(form.invoice_items.map((item) => item.uid))"
                light
                circle
                size="small"
                v-tooltip="t(`invoice.tooltip.${isExpandedInvoiceItemsEmpty ? 'minimize_all' : 'expand_all'}`)"
                :disabled="includesInvoiceItemWithoutRequiredComment"
              >
                <FontIcon :name="isExpandedInvoiceItemsEmpty ? 'chevrons-up' : 'chevrons-down'" />
              </AppButton>
            </AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody
          v-for="invoiceItem in form.invoice_items"
          :key="invoiceItem.uid"
          :class="{ 'is-invalid-invoice-item': !isInvoiceItemValid(invoiceItem) }"
        >
          <!-- Invoices -->
          <AppTableTr style="border-top-width: 5px">
            <!-- Project -->
            <AppTableTd>
              <div v-if="invoiceItem.custom && !invoiceItem.id" class="form-wrapper is-small">
                <select
                  @change="setInvoiceItemDescription(invoiceItem)"
                  class="form-control"
                  v-model="invoiceItem.project_id"
                  required
                >
                  <option :value="null" selected disabled hidden v-text="t('common.select')" />
                  <option v-for="option in projects" :key="option.id" :value="option.id" v-text="option.name" />
                </select>
              </div>
              <span v-else v-text="invoiceItem.project_name" />
            </AppTableTd>
            <!-- Marginality -->
            <AppTableTd nowrap>
              <span
                v-if="invoiceItem.project_id && projectMargins[invoiceItem.project_id]"
                v-text="projectMargins[invoiceItem.project_id].marginality_percent"
              />
              <EmptyValue v-else />
            </AppTableTd>
            <!-- Price type -->
            <AppTableTd nowrap>
              <div v-if="invoiceItem.custom && editable" class="form-wrapper is-small">
                <select
                  @change="onInvoiceItemChoosePriceType(invoiceItem)"
                  class="form-control"
                  v-model="invoiceItem.invoice_template_id"
                  required
                >
                  <option :value="null" selected disabled hidden v-text="t('common.select')" />
                  <option
                    v-for="option in invoiceItemManualTemplates"
                    :key="option.id"
                    :value="option.id"
                    v-text="t(`invoice.price_type.${option.price_type}`)"
                  />
                </select>
              </div>
              <span v-else v-text="t(`invoice.price_type.${invoiceItem.price_type}`)" />
            </AppTableTd>
            <!-- Description -->
            <AppTableTd>
              <FormTextarea v-if="editable" size="small" v-model="invoiceItem.description" />
              <span v-else v-text="invoiceItem.description" />
            </AppTableTd>
            <!-- Quick links -->
            <AppTableTd nowrap>
              <div class="d-flex align-items-center">
                <RouterLink
                  v-tooltip.top="t('invoice.tooltip.report_link')"
                  class="ml-1"
                  :to="{
                    name: 'client.reported_time',
                    params: { uuid: invoice.client.uuid },
                    query: {
                      project: invoiceItem.project_id,
                      from: invoiceItem.custom
                        ? DateTime.local(invoice.time_period.year, invoice.time_period.month, 1)
                            .startOf('month')
                            .toFormat('yyyy-MM-dd')
                        : invoiceItem.invoiced_period.start,
                      to: invoiceItem.custom
                        ? DateTime.local(invoice.time_period.year, invoice.time_period.month, 1)
                            .endOf('month')
                            .toFormat('yyyy-MM-dd')
                        : invoiceItem.invoiced_period.end,
                    },
                  }"
                  target="_blank"
                  :class="{ disabled: !invoiceItem.project_id }"
                >
                  <AppButton ghost size="small" light circle>
                    <FontIcon name="report" />
                  </AppButton>
                </RouterLink>
                <RouterLink
                  v-tooltip.top="t('invoice.tooltip.billing_link')"
                  class="ml-1"
                  :to="{
                    name: 'client.billing',
                    params: { uuid: invoice.client.uuid },
                  }"
                  target="_blank"
                  :class="{ disabled: !invoiceItem.project_id }"
                >
                  <AppButton ghost size="small" light circle>
                    <FontIcon name="file-invoice" />
                  </AppButton>
                </RouterLink>
                <RouterLink
                  v-tooltip.top="t('invoice.tooltip.project_link')"
                  class="ml-1"
                  :to="{ name: 'projects.view', params: { uuid: invoice.client.uuid, id: invoiceItem.project_id } }"
                  target="_blank"
                  :class="{ disabled: !invoiceItem.project_id }"
                >
                  <AppButton ghost size="small" light circle>
                    <FontIcon name="briefcase" />
                  </AppButton>
                </RouterLink>
              </div>
            </AppTableTd>
            <!-- ERP Article -->
            <AppTableTd nowrap>
              <div class="form-wrapper is-small">
                <select
                  @change="onInvoiceItemChooseArticle(invoiceItem)"
                  v-model="invoiceItem.erp_article_id"
                  class="form-control"
                  required
                  v-if="editable"
                >
                  <option :value="null" selected disabled hidden v-text="t('common.select')" />
                  <option
                    v-for="option in erpArticles"
                    :key="option.id"
                    :value="option.id"
                    v-text="option[`name_${form.language}`]"
                  />
                </select>
                <span v-if="!editable && invoiceItem.erp_article_id">
                  {{ getErpArticle(invoiceItem.erp_article_id)![`name_${form.language}`] }}
                </span>
              </div>
            </AppTableTd>
            <!-- Quantity -->
            <AppTableTd class="text-right" nowrap>
              <div v-if="editable" class="form-wrapper is-small">
                <input
                  type="number"
                  :value="invoiceItem.quantity"
                  @change="
                    invoiceItem.custom
                      ? onCustomInvoicingQtyChange(invoiceItem, $event)
                      : onInvoicingQtyChange(invoiceItem, $event)
                  "
                  class="text-right form-control"
                  min="0"
                  step=".01"
                  required
                />
              </div>
              <span v-else v-text="invoiceItem.quantity" />
            </AppTableTd>
            <!-- Unit -->
            <AppTableTd nowrap>st</AppTableTd>
            <!-- Price per unit -->
            <AppTableTd class="text-right" nowrap>
              <div v-if="editable" class="form-wrapper is-small">
                <input
                  type="number"
                  :value="invoiceItem.price_per_unit"
                  @change="
                    invoiceItem.custom
                      ? onCustomPricePerUnitChange(invoiceItem, $event)
                      : onInvoicingPricePerUnitChange(invoiceItem, $event)
                  "
                  class="text-right form-control"
                  :min="isInvoiceItemAdvance(invoiceItem) ? undefined : 0"
                  step=".01"
                />
              </div>
              <span
                v-else
                v-text="n(calculateAmount(1, invoiceItem.price_per_unit, invoiceItem.discount), 'decimal')"
              />
            </AppTableTd>
            <!-- Discount -->
            <AppTableTd class="text-right" nowrap>
              <div v-if="editable" class="form-wrapper is-small">
                <input
                  type="number"
                  :value="invoiceItem.discount"
                  @change="onDiscountChange(invoiceItem, $event)"
                  class="text-right form-control"
                  max="100"
                  min="0"
                  step="1"
                  :disabled="
                    !invoiceItem.project_id ||
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                />
              </div>
              <span v-else v-text="invoiceItem.discount" />
            </AppTableTd>
            <!-- Total price -->
            <AppTableTd class="text-right" nowrap>
              <div v-if="editable" class="form-wrapper is-small has-icon">
                <i
                  v-if="
                    getInvoicingSum(invoiceItem) !== 0 &&
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                  class="form-icon ti ti-minus"
                />
                <input
                  type="number"
                  :value="invoiceItem.price"
                  @change="
                    invoiceItem.custom
                      ? onCustomInvoicingSumChange(invoiceItem, $event)
                      : onInvoicingSumChange(invoiceItem, $event)
                  "
                  class="text-right form-control"
                  min="0"
                  step=".01"
                  required
                />
              </div>
              <span v-else v-text="n(subtractPercent(invoiceItem.price, invoiceItem.discount), 'decimal')" />
            </AppTableTd>
            <!-- Actions -->
            <AppTableTd class="text-right" nowrap>
              <template v-if="!invoiceItem.custom && !isInvoiceItemAdvance(invoiceItem)">
                <AppButton
                  v-if="editable"
                  v-tooltip="t('invoice.tooltip.postpone')"
                  @click.stop.prevent="onPostpone(invoiceItem)"
                  size="small"
                  light
                  circle
                  color="warning"
                  class="ml-2"
                  :disabled="invoiceItem.price === 0"
                >
                  <FontIcon name="chevrons-right" />
                </AppButton>
                <AppButton
                  v-if="editable"
                  v-tooltip="t('invoice.tooltip.write_off')"
                  @click.stop.prevent="onWriteOff(invoiceItem)"
                  size="small"
                  light
                  circle
                  color="danger"
                  class="ml-2"
                  :disabled="invoiceItem.price === 0"
                >
                  <FontIcon name="cash-banknote-off" />
                </AppButton>
                <AppButton
                  light
                  circle
                  size="small"
                  class="ml-2"
                  @click.stop.prevent="toggleInvoiceItem(invoiceItem.uid)"
                  v-tooltip="t(`invoice.tooltip.${isExpandedInvoiceItem(invoiceItem.uid) ? 'minimize' : 'expand'}`)"
                  :disabled="invoiceItem.write_off.sum > 0 && invoiceItem.write_off.comment === ''"
                >
                  <FontIcon
                    :name="isExpandedInvoiceItem(invoiceItem.uid) ? 'chevron-up' : editable ? 'pencil' : 'chevron-down'"
                  />
                </AppButton>
              </template>
              <AppButton
                v-if="editable && invoiceItem.custom"
                v-tooltip="t('common.delete')"
                @click.stop.prevent="onDeleteCustomInvoiceItem(invoiceItem.uid)"
                size="small"
                light
                circle
                color="danger"
                class="ml-2"
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
          <template v-if="invoiceItem.custom === false">
            <!-- Postpone -->
            <AppTableTr v-show="isExpandedInvoiceItem(invoiceItem.uid)">
              <AppTableTd colspan="3" />
              <AppTableTd colspan="2" class="text-right">
                <FormInput
                  v-if="editable"
                  size="small"
                  v-model="invoiceItem.postpone.comment"
                  :placeholder="t('common.comment')"
                />
                <span v-else v-text="invoiceItem.postpone.comment" />
              </AppTableTd>
              <AppTableTd class="font-bold">{{ t('invoice.details.postpone_to_next_month') }}:</AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <div v-if="editable" class="form-wrapper is-small">
                  <input
                    type="number"
                    :value="invoiceItem.postpone.quantity"
                    @change="onPostponeQtyChange(invoiceItem, $event)"
                    class="text-right form-control"
                    min="0"
                    step=".01"
                    required
                  />
                </div>
                <span v-else v-text="invoiceItem.postpone.quantity" />
              </AppTableTd>
              <AppTableTd :colspan="1" />
              <AppTableTd class="text-right" nowrap>
                <div v-if="editable" class="form-wrapper is-small">
                  <input
                    type="number"
                    :value="invoiceItem.postpone.price_per_unit"
                    @change="onPostponePricePerUnitChange(invoiceItem, $event)"
                    class="text-right form-control"
                    step=".01"
                  />
                </div>
                <span v-else v-text="n(invoiceItem.postpone.price_per_unit, 'decimal')" />
              </AppTableTd>
              <!-- Discount -->
              <AppTableTd class="text-right" nowrap>
                <div v-if="editable" class="form-wrapper is-small">
                  <input
                    type="number"
                    :value="invoiceItem.postpone.discount"
                    @change="onPostponeDiscountChange(invoiceItem, $event)"
                    class="text-right form-control"
                    max="100"
                    min="0"
                    step="1"
                    :disabled="
                      !invoiceItem.project_id ||
                      getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                    "
                  />
                </div>
                <span v-else v-text="invoiceItem.postpone.discount" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <span
                  v-if="
                    invoiceItem.postpone.sum !== 0 &&
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                  v-text="'-'"
                />
                <div v-if="editable" class="form-wrapper is-small">
                  <input
                    type="number"
                    :value="invoiceItem.postpone.sum"
                    @change="onPostponeSumChange(invoiceItem, $event)"
                    class="text-right form-control"
                    min="0"
                    step=".01"
                    required
                  />
                </div>
                <span v-else v-text="n(subtractPercent(invoiceItem.postpone.sum, invoiceItem.discount), 'decimal')" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <AppButton
                  v-if="editable"
                  v-tooltip="t('common.cancel')"
                  @click.stop.prevent="discardPostpone(invoiceItem)"
                  size="small"
                  light
                  circle
                  color="danger"
                  :disabled="invoiceItem.postpone.sum === 0"
                >
                  <FontIcon name="x" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
            <!-- Write Off -->
            <AppTableTr v-show="isExpandedInvoiceItem(invoiceItem.uid)">
              <AppTableTd colspan="3" />
              <AppTableTd colspan="2" class="text-right" nowrap>
                <FormInput
                  v-if="editable"
                  size="small"
                  v-model="invoiceItem.write_off.comment"
                  :placeholder="`${t('common.comment')} ${invoiceItem.write_off.sum > 0 ? '*' : ''}`"
                  :required="isExpandedInvoiceItem(invoiceItem.uid) && invoiceItem.write_off.sum > 0"
                  :invalid="invoiceItem.write_off.sum > 0 && invoiceItem.write_off.comment === ''"
                />
                <span v-else v-text="invoiceItem.write_off.comment" />
              </AppTableTd>
              <AppTableTd class="font-bold" nowrap> {{ t('invoice.details.write_off') }}:</AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <!-- {{ invoiceItem.write_off.quantity }}-->
              </AppTableTd>
              <AppTableTd :colspan="2" />
              <AppTableTd class="text-right">
                <!-- {{ n(invoiceItem.write_off.price_per_unit, 'currency') }}-->
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <span
                  v-if="
                    invoiceItem.write_off.sum !== 0 &&
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                  v-text="'-'"
                />
                <div v-if="editable" class="form-wrapper is-small">
                  <input
                    type="number"
                    :value="invoiceItem.write_off.sum"
                    @change="onWriteOffSumChange(invoiceItem, $event)"
                    class="text-right form-control"
                    min="0"
                    step=".01"
                    required
                  />
                </div>
                <span v-else v-text="n(subtractPercent(invoiceItem.write_off.sum, invoiceItem.discount), 'decimal')" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <AppButton
                  v-if="editable"
                  v-tooltip="t('common.cancel')"
                  @click.stop.prevent="discardWriteOff(invoiceItem)"
                  size="small"
                  light
                  circle
                  color="danger"
                  :disabled="invoiceItem.write_off.sum === 0"
                >
                  <FontIcon name="x" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
            <!-- Original -->
            <AppTableTr v-show="isExpandedInvoiceItem(invoiceItem.uid)">
              <AppTableTd colspan="5" />
              <AppTableTd class="font-bold" nowrap> {{ t('invoice.details.total') }}:</AppTableTd>
              <AppTableTd class="text-right font-bold" nowrap>
                {{ invoiceItem.original_quantity }}
              </AppTableTd>
              <AppTableTd />
              <AppTableTd />
              <AppTableTd class="text-right font-bold" nowrap>
                {{ n(invoiceItem.original_price_per_unit, 'decimal') }}
              </AppTableTd>

              <AppTableTd class="text-right font-bold" nowrap>
                <strong
                  v-if="
                    invoiceItem.original_price !== 0 &&
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                  v-text="'-'"
                />
                {{ n(invoiceItem.original_price, 'decimal') }}
              </AppTableTd>
              <AppTableTd />
            </AppTableTr>
            <!-- Alerts -->
            <AppTableTr
              v-if="editable && (!isInvoiceItemValid(invoiceItem) || showNegativeAdvanceBalanceWarning(invoiceItem))"
            >
              <AppTableTd :colspan="12">
                <AppAlert v-if="showNegativeAdvanceBalanceWarning(invoiceItem)" type="warning">
                  <FontIcon name="alert-circle" />
                  {{ t('invoice.negative_advance') }}
                </AppAlert>
                <AppAlert v-if="!isInvoiceItemValid(invoiceItem)" type="danger">
                  <span
                    v-if="invoiceItem.price < invoiceItem.original_price"
                    v-text="t('invoice.price_doesnt_much_original')"
                  />
                  <span v-else v-text="t('invoice.something_off')" />
                </AppAlert>
              </AppTableTd>
            </AppTableTr>
          </template>
        </AppTableBody>
        <AppTableBody>
          <AppTableTr style="border-top-width: 5px">
            <AppTableTd :colspan="9">
              <h3 class="mb-0" v-text="t('invoice.details.total_invoice_amount')" />
            </AppTableTd>
            <AppTableTd colspan="2" class="text-right" nowrap>
              <h3 class="mb-0" v-text="n(totalPrice, 'currency')" />
            </AppTableTd>
            <AppTableTd class="text-right" nowrap>
              <AppButton
                v-if="editable"
                v-tooltip="t('common.add')"
                @click.stop.prevent="addCustomItem"
                light
                circle
                color="success"
              >
                <FontIcon name="plus" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppBox class="mt-3" shadow>
        <template v-if="editable">
          <AppBoxBody v-if="projectsWithoutExistedAdvanceBalance.length > 0">
            <AppAlert
              v-for="project in projectsWithoutExistedAdvanceBalance"
              :key="project.id"
              class="mb-2"
              type="danger"
            >
              <h3>
                <FontIcon name="alert-triangle" />
                {{ project.name }}
              </h3>
              <div v-html="t('invoice.no_positive_prepayment')" />
            </AppAlert>
          </AppBoxBody>
          <AppBoxBody v-if="projectsWithExistedAdvanceBalance.length > 0">
            <h2>{{ t('invoice.not_settled_prepayments') }}</h2>
            <AppAlert v-for="project in projectsWithExistedAdvanceBalance" :key="project.id" class="mb-2" type="info">
              <h3>
                <FontIcon name="info-square-rounded" />
                {{ project.name }}
              </h3>
              <div v-html="t('invoice.not_settled_prepayments_text', { value: project.advance_balance })" />
            </AppAlert>
          </AppBoxBody>
        </template>
        <AppBoxBody>
          <div class="form-group">
            <FormLabel class="font-bold" html-for="invoice_invoice_text">
              {{ t('invoice.attributes.invoice_text') }}
            </FormLabel>
            <FormTextarea v-if="editable" rows="4" id="invoice_invoice_text" v-model="form.invoice_text" />
            <span v-else v-text="form.invoice_text ?? t('common.empty')" />
          </div>
          <div class="form-group">
            <FormLabel class="font-bold" html-for="invoice_internal_note">
              {{ t('invoice.attributes.internal_note') }}
            </FormLabel>
            <FormTextarea v-if="editable" rows="4" id="invoice_internal_note" v-model="form.internal_note" />
            <span v-else v-text="form.internal_note ?? t('common.empty')" />
          </div>
          <AppAlert type="warning" v-if="!invoice.is_meta_exists">
            {{ t('invoice.customer_settings_incomplete.text') }}
            <RouterLink :to="{ name: 'client.settings', params: { uuid: invoice.client.uuid } }" target="_blank">
              {{ t('invoice.customer_settings_incomplete.link') }}
            </RouterLink>
          </AppAlert>
        </AppBoxBody>
      </AppBox>
      <div class="d-flex flex-nowrap mt-4">
        <RouterLink
          v-if="closestInvoices.prev"
          custom
          :to="{ name: 'invoices.edit', params: { id: closestInvoices.prev } }"
          v-slot="{ href, navigate }"
        >
          <a
            @click="navigate"
            :href="href"
            :class="{ disabled: submitLoader.isLoading.value || syncLoader.isLoading.value }"
          >
            <AppButton light :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value">
              {{ t('common.previous') }}
            </AppButton>
          </a>
        </RouterLink>
        <RouterLink
          v-if="closestInvoices.next"
          custom
          :to="{ name: 'invoices.edit', params: { id: closestInvoices.next } }"
          v-slot="{ href, navigate }"
        >
          <a
            @click="navigate"
            :href="href"
            class="ml-2"
            :class="{ disabled: submitLoader.isLoading.value || syncLoader.isLoading.value }"
          >
            <AppButton light :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value">
              {{ t('common.next') }}
            </AppButton>
          </a>
        </RouterLink>
        <AppButton
          @click.prevent="onFetchNotInvoiced"
          class="ml-auto"
          light
          :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value"
        >
          {{ t('invoice.details.fetch_not_invoiced_prices') }}
        </AppButton>
        <AppButton
          v-if="can('invoices.delete', invoice.internal_status)"
          v-tooltip.left="t('invoice.tooltip.delete')"
          @click.stop.prevent="onDelete"
          class="ml-2"
          color="danger"
          :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value"
        >
          {{ t('invoice.confirm.delete.action') }}
        </AppButton>
        <AppButton
          v-if="editable"
          class="ml-2"
          color="success"
          :loading="submitLoader.isLoading.value"
          :disabled="
            submitLoader.isLoading.value ||
            syncLoader.isLoading.value ||
            projectsWithoutExistedAdvanceBalance.length > 0
          "
        >
          {{ t('common.save') }}
        </AppButton>
        <AppButton
          @click.prevent="approveAndSync"
          class="ml-2"
          color="secondary"
          :loading="syncLoader.isLoading.value"
          v-if="isDraft"
          :disabled="
            !areInvoiceItemsValid ||
            submitLoader.isLoading.value ||
            syncLoader.isLoading.value ||
            !invoice.is_meta_exists
          "
        >
          {{ t('invoice.details.approve_and_sync') }}
        </AppButton>
        <AppButton
          @click.prevent="approveAndSync"
          class="ml-2"
          color="secondary"
          :loading="syncLoader.isLoading.value"
          v-if="isActionRequired"
          :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value"
        >
          {{ t('invoice.details.save_and_sync') }}
        </AppButton>
      </div>
    </form>
  </div>
  <LeaveConfirmModal :is-revealed="isRevealed" @confirm="confirm" @cancel="cancel" />
</template>
<style>
.table td,
.table th {
  padding: 5px;
}
</style>
